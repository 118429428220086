import * as React from "react"
import { Button, Heading, Image, Stack, StackDirection, Text, VStack, useBreakpointValue } from "@chakra-ui/react";

import { Trans } from "react-i18next";
import { IDS } from "../../../shared/Constants";

const Platform = () => {
    const responsiveRowDirection = useBreakpointValue<StackDirection>({
        base: "column",
        md: "row",
    });
    const responsiveWidth = useBreakpointValue({
        base: "14rem",
        sm: "15rem",
        md: "18rem",
        lg: "19rem",
        xl: "23rem",
    });

    return (
        <>
            <VStack spacing={"6rem"}>
                <Stack direction={responsiveRowDirection} gap={"4rem"} alignItems={"center"} h={"20rem"}>
                    <VStack alignItems={"left"}>
                        <Heading textAlign={"left"} fontSize={"25px"} color="primary.main">
                            <Trans i18nKey="platform.what.title" >
                                For users with computer vision needs
                            </Trans>
                        </Heading>
                        <Heading textAlign={"left"} fontSize={"40px"} >
                            <Trans i18nKey="platform.what.camera.title">
                                Camera, User and
                                Infrastructure Management
                            </Trans>
                        </Heading>
                    </VStack>

                    <VStack alignItems={"left"} gap={"1rem"}>
                        <Text textAlign={"left"} fontWeight={"600"} color={"gray.500"}>
                            <Trans i18nKey="platform.what.camera.description">
                                The platform.what allows for users to connect
                                to their existing camera systems. They
                                are able to manage individual cameras,
                            </Trans>
                        </Text>
                    </VStack>
                </Stack>


                <Stack direction={responsiveRowDirection} gap={"4rem"} alignItems={"center"} >


                    <Image src={`${process.env.PUBLIC_URL}/cctv-data.png`} mt={"4rem"} boxSize={responsiveWidth} borderRadius={"xl"} color={"primary"} />

                    <VStack alignItems={"left"}>
                        <Heading textAlign={"left"} size={"lg"}>
                            <Trans i18nKey="platform.what.applications.title">
                                Applications
                            </Trans>
                        </Heading>
                        <Text textAlign={"left"} fontWeight={"600"} color={"gray.500"} >
                            <Trans i18nKey="platform.what.applications.description">
                                Once cameras and locations are
                                registered onto the platform.what, users can
                                choose to subscribe to and install
                                applications. These applications use the
                                registered cameras to run detections on
                                the video stream, and to apply custom
                                business rules.
                            </Trans>
                        </Text>

                        <div >
                            <Button as="a" href={`#${IDS.STEPS}`}
                                colorScheme="primary" variant={"outline"}

                            >
                                <Trans i18nKey="platform.what.applications.button">
                                    See steps
                                </Trans>
                            </Button>
                        </div>
                    </VStack>
                </Stack>
                <div id={IDS.STEPS} />
            </VStack>
        </>
    )
}

export default Platform;