import React from "react";
import Start from "./content/Start"
import Platform from "./content/Platform"
import Contact from "./content/Contact"
import How from "./content/How"
import Usecases from "./content/Usecases"
import Content from "../../shared/Content"
import { IDS } from "../../shared/Constants"
import About from "./content/About"
import { VStack } from "@chakra-ui/react";

type Props = {
    currentTab: string;
    setCurrentTab: (tab: string) => void;
}

const Home = ({currentTab, setCurrentTab}: Props) => {
    return (<VStack spacing={8} >
        <Content index={0} id={IDS.HOME} title="Home" setCurrentTab={setCurrentTab}
          isCurrentTab={currentTab === IDS.HOME}
        >
          <Start />
        </Content>
        <Content index={1} id={IDS.PLATFORM} title="Platform" setCurrentTab={setCurrentTab}

          isCurrentTab={currentTab === IDS.PLATFORM}
        >
          <Platform />
        </Content>
        <Content index={4} id={IDS.USE_CASES} title="Usecases" setCurrentTab={setCurrentTab}
          isCurrentTab={currentTab === IDS.USE_CASES}>
          <Usecases />
        </Content>
        <Content index={2} id={IDS.PLATFORM} title="Platform" setCurrentTab={setCurrentTab}
          isCurrentTab={currentTab === IDS.PLATFORM}>
          <How />
        </Content>
        <Content index={4} id={IDS.ABOUT} title="About" setCurrentTab={setCurrentTab}
          isCurrentTab={currentTab === IDS.ABOUT}>
          <About />
        </Content>
        <Content index={5} id={IDS.CONTACT} title="Contact" setCurrentTab={setCurrentTab} isCurrentTab={currentTab === IDS.CONTACT}>
          <Contact />
        </Content>
      </VStack>)
}

export default Home