import * as React from "react"
import {
    Heading,
    Text,
    VStack,
    HStack,
    Avatar, Button, Link, Wrap
} from "@chakra-ui/react";

import { Trans } from "react-i18next";
import { FaLinkedin } from "react-icons/all";

type TeamMember = {
    fullName: string;
    icon: string;
    linkedin: string;
}

const teamMembers: TeamMember[] = [
    {
        fullName: "Christine Pinard",
        icon: "christine.png",
        linkedin: "https://www.linkedin.com/in/christine-pinard-917693259/"
    },
    {
        fullName: "Alexandre Potvin-Demers",
        icon: "alex.png",
        linkedin: "https://linkedin.com/in/alexpotv"
    },
    {
        fullName: "Marc-Antoine Robin",
        icon: "marc.png",
        linkedin: "https://www.linkedin.com/in/marc-antoine-robin/"
    }
]

const About = () => {
    const TeamMemberComponent = ({ fullName, icon, linkedin }: TeamMember) => {
        return <VStack justifyContent={"center"}>
            <Avatar
                boxSize={"13rem"}
                src={`${process.env.PUBLIC_URL}/${icon}`}
                backgroundColor={"primary.main"}
                borderWidth={"10px"}
                borderColor={"primary.text"}
                name={fullName}
                bg={"primary.main"}
            />
            <Heading fontSize={"18px"}>{fullName}</Heading>
            <HStack justifyContent={"center"}>
                <Link href={linkedin}>
                    <Button colorScheme={"linkedin"} leftIcon={<FaLinkedin />}>
                        LinkedIn
                    </Button>
                </Link>
            </HStack>
        </VStack>
    }


    return (
        <>
            <VStack alignItems={"left"}>
                <Heading textAlign={"left"} fontSize={"25px"} color="primary.main">
                    <Trans i18nKey="about.title">about title
                    </Trans>
                </Heading>
                <VStack alignItems={"left"} marginBottom={"5rem"} marginTop={"1rem"}>
                    <Heading textAlign={"left"} fontSize={"40px"} >
                        <Trans i18nKey="about.1.title">
                            about 1 title
                        </Trans>
                    </Heading>
                    <Text textAlign={"left"} fontSize={"18px"} fontWeight={"600"} color={"gray.500"}
                        __css={{
                            p: {
                                paddingTop: "2rem",
                            }
                        }}>
                        <Trans i18nKey="about.1.description">
                            about 1 description
                        </Trans>
                    </Text>
                </VStack>

                <VStack alignItems={"left"}>
                    <Heading textAlign={"left"} fontSize={"40px"}>
                        <Trans i18nKey="about.2.title">
                            Applications
                        </Trans>
                    </Heading>
                    <Text textAlign={"left"} fontSize={"18px"} fontWeight={"600"} color={"gray.500"}>
                        <Trans i18nKey="about.2.description">
                            about 2 description
                        </Trans>
                    </Text>
                    <Wrap justify={"center"} mt={"4rem"} mb={"4rem"} spacing={"4em"}>
                        <TeamMemberComponent {...teamMembers[0]} />
                        <TeamMemberComponent {...teamMembers[1]} />
                        <TeamMemberComponent {...teamMembers[2]} />
                    </Wrap>
                    <Text textAlign={"left"} fontSize={"18px"} fontWeight={"600"} color={"gray.500"}>
                        <Trans i18nKey={"about.2.descriptionPart2"}>
                            about 2 description next part
                        </Trans>
                    </Text>
                </VStack>
            </VStack>
        </>
    )
}

export default About;