export const IDS = {
    HOME: "Home",
    PLATFORM: "Platform",
    CREATE: "Create",
    BUILD: "Build",
    PROVIDE: "Provide",
    EXAMPLE: "Example",
    STEPS: "Steps",
    USE_CASES: "Usecases",
    ABOUT: "About",
    CONTACT: "Contact",
    OOH: {
        HOME: "Home",
        CONTACT: "Contact",
    }
}