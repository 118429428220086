import { IDS } from "../shared/Constants";

export const mainTabs = [
    {
        name: "Platform",
        key: "platform",
        path: `#${IDS.PLATFORM}`
    },
    {
        name: "Usecases",
        key: "usecases",
        path: `#${IDS.USE_CASES}`
    },
    {
        name: "About",
        key: "about",
        path: `#${IDS.ABOUT}`
    },
]

export const oohTabs = [
    {
        name: "Out of home",
        key: "ooh.main",
        path: `#${IDS.OOH.HOME}`
    }
]