import * as React from "react"
import { Button, Heading, VStack, Wrap, WrapItem } from "@chakra-ui/react";

import { Trans, useTranslation } from "react-i18next";
import Step from "../../../shared/Step";
import { CheckIcon, DragHandleIcon, PlusSquareIcon } from "@chakra-ui/icons";
import { IDS } from "../../../shared/Constants";

const How = () => {
    const { t } = useTranslation();

    return <>
        <VStack spacing={"5rem"} alignItems={"left"}>
            <VStack spacing={"1rem"} alignItems={"left"}>
                <Heading textAlign={"left"} fontSize={"25px"} color="primary.main">
                    <Trans i18nKey="platform.how.title">
                        How to use
                    </Trans>
                </Heading>
                <Heading textAlign={"left"} fontSize={"40px"}>
                    <Trans i18nKey="platform.how.description">
                        How description
                    </Trans>
                </Heading>
            </VStack>

            <Wrap spacing={"4em"} justify={"center"}>
                <WrapItem>
                    <Step
                        title={t("platform.how.1.title")}
                        description={t("platform.how.1.description")}
                        icon={<PlusSquareIcon color={"primary.main"} fontSize={"40px"} />}
                    />
                </WrapItem>
                <WrapItem>
                    <Step
                        title={t("platform.how.2.title")}
                        description={t("platform.how.2.description")}
                        icon={<DragHandleIcon color={"primary.main"} fontSize={"40px"} />}
                    />
                </WrapItem>
                <WrapItem>
                    <Step
                        title={t("platform.how.3.title")}
                        description={t("platform.how.3.description")}
                        icon={<CheckIcon color={"primary.main"} fontSize={"40px"} />}
                    />
                </WrapItem>

            </Wrap>

            <Button as="a" href={`#${IDS.CONTACT}`}
                colorScheme="primary"
                color={"primary.text"}
                variant={"solid"}
                bg={"primary.main"}
            >
                <Trans i18nKey="platform.how.start">
                    Start now
                </Trans>
            </Button>
        </VStack>
    </>
}

export default How;