import * as React from "react"
import { Logo } from "../shared/Logo"
import {
    Box,
    Button,
    Collapse,
    Container,
    Flex,
    HStack,
    IconButton,
    LightMode,
    Link,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    useBreakpointValue,
    useDisclosure,
} from "@chakra-ui/react"
import { CloseIcon, HamburgerIcon } from "@chakra-ui/icons"
import { IDS } from "../shared/Constants"
import { Trans, useTranslation } from "react-i18next"

export type TabInfo = {
    name: string;
    key: string;
    path: string;
}

type Props = {
    tabs: TabInfo[],
    currentTab: string,
    setCurrentTab: (tab: string) => void,
}

const TobBar = ({ tabs, currentTab, setCurrentTab }: Props) => {
    const { isOpen, onToggle } = useDisclosure();
    const [isAtTopScroll, setIsAtTopScroll] = React.useState(true);
    const { t, i18n } = useTranslation();
    const logoSize = useBreakpointValue({
        base: "1rem",
        md: "1.6rem"
    }, {
        fallback: "md"
    })

    React.useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 0) {
                setIsAtTopScroll(false);
            } else {
                setIsAtTopScroll(true);
            }
        }
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        }
    }
        , [])


    const changeLanguage = (language: string) => {
        i18n.changeLanguage(language);
        window.location.reload();
    };


    const Tabs = () => {
        return (
            <>
                {tabs.map((tab, index) => {
                    const isActived = currentTab === tab.path.replace("#", "");
                    return (
                        <Button
                            as={"a"}
                            key={index}
                            variant={isActived ? "solid" : "ghost"}
                            color={isActived ? "primary.main" : "text.main"}
                            href={tab.path}
                            onClick={() => setCurrentTab(tab.path.replace("#", ""))}
                        >
                            <Trans i18nKey={`tabs.${tab.key}`}>
                                {tab.name}
                            </Trans>
                        </Button>
                    )
                })}
            </>
        )
    }

    return (
        <Box style={{
            position: "sticky",
            top: "0",
            zIndex: 1,
            backgroundColor: isAtTopScroll ? undefined : "rgba(255, 255, 255, 0.2)",
            backdropFilter: isAtTopScroll ? undefined : "blur(10px)",
            padding: "20px",
        }}>
            <Container h="4rem" maxW={"1400px"} justifyContent={"center"} >
                <Flex h={"100%"} alignItems={"center"} justifyContent={"space-between"}>
                    <Flex h={"100%"} alignItems={"center"} w={"15rem"}>
                        <Flex
                            display={{ base: 'flex', lg: 'none' }}>
                            <IconButton
                                onClick={onToggle}
                                icon={isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />}
                                variant={'ghost'}
                                color={'text.main'}
                                aria-label={'Toggle Navigation'}
                            />
                        </Flex>
                        <Link href="/">
                            <Logo h={logoSize} pointerEvents="none" />
                        </Link>

                    </Flex>

                    <HStack display={{ base: 'none', lg: 'flex' }} spacing={2} justifyContent={"center"}>
                        <Tabs />
                    </HStack>
                    <HStack spacing={2} >
                        <LightMode>
                            <Menu matchWidth>
                                <MenuButton as={Button} variant="ghost" color="text.secondary">
                                    {t("languageAbbreviation")}
                                </MenuButton>
                                <MenuList color={"text.main"} >
                                    <MenuItem onClick={() => changeLanguage("fr")}>fr</MenuItem>
                                    <MenuItem onClick={() => changeLanguage("en")}>en</MenuItem>
                                </MenuList>
                            </Menu>
                        </LightMode>
                        <Button variant={"ghost"} as="a" target="_blank" href={`https://blog.boxcv.ca/`} color="text.main">
                            <Trans i18nKey="bar.blog">
                                Blog
                            </Trans>
                        </Button>
                        <Button variant={"outline"} as="a" href={`#${IDS.CONTACT}`} colorScheme="primary">
                            <Trans i18nKey="bar.contact">
                                Reach out
                            </Trans>
                        </Button>
                    </HStack>
                </Flex>
                <Collapse in={isOpen} animateOpacity >
                    <Flex justifyContent={"flex-start"}>
                        <Box
                            boxShadow={"xl"}
                            rounded={"md"}
                            mt={3}
                            p={4}
                            overflow={"auto"}
                            backgroundColor={"rgba(255, 255, 255, 0.2)"}
                            backdropFilter={"blur(10px)"}
                            onMouseLeave={() => onToggle()}
                        >
                            <Flex flexDirection={"column"} alignItems={"flex-start"}  >
                                <Tabs />
                            </Flex>
                        </Box>
                    </Flex>
                </Collapse>
            </Container>
        </Box>
    )
}

export default TobBar