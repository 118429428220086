import React, { ReactNode } from "react";
import {
  ChakraProvider,
} from "@chakra-ui/react"
import theme from "./theme"
import TopBar, { TabInfo } from "./layout/TopBar"
import ReactGA from "react-ga";
import CookieConsent from "./shared/CookieConsent/CookieConsent";
import { Route, Routes } from "react-router"
import Home from "./pages/home/Home";
import OOH from "./pages/ooh/OOH";
import { mainTabs, oohTabs } from "./layout/tabs";

ReactGA.initialize("G-S48777ZCB2")

export const App = () => {
  const [currentTab, setCurrentTab] = React.useState("home");


  const Layout = ({children, tabs}:{children:ReactNode, tabs: TabInfo[]}) => {
    return <>
    <TopBar currentTab={currentTab} setCurrentTab={setCurrentTab} tabs={tabs}/>
    {children}
    </>
  }

  return (
    <ChakraProvider theme={theme}>
      <CookieConsent current_tab={currentTab}/>
      <Routes>
        <Route index element={<Layout tabs={mainTabs}><Home currentTab={currentTab} setCurrentTab={setCurrentTab}/></Layout>} />
        <Route path="/ooh" element={<Layout tabs={oohTabs}><OOH currentTab={currentTab} setCurrentTab={setCurrentTab}/></Layout>}/>
      </Routes>
    </ChakraProvider>
  )
}
