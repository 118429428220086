import * as React from "react"
import { Heading, VStack, Wrap, WrapItem } from "@chakra-ui/react";

import { Trans } from "react-i18next";
import TrafficAnalysisUseCase from "./UseCases/TrafficAnalysisUseCase";
import ParkingMonitoringUseCase from "./UseCases/ParkingMonitoringUseCase";
import BehaviourClassificationUseCase from "./UseCases/BehaviourClassificationUseCase";
import OccupancyMonitoringUseCase from "./UseCases/OccupancyMonitoringUseCase";
import AndMoreUseCases from "./UseCases/AndMoreUseCases";
import AudienceMeasurementUseCase from "./UseCases/AudienceMeasurement";
import { Link } from "react-router";

const Usecases = () => {
    return (
        <>
            <VStack spacing={"6rem"} alignItems={"left"}>
                <VStack spacing={"1rem"} alignItems={"left"}>
                    <Heading textAlign={"left"} fontSize={"25px"} color="primary.main">
                        <Trans i18nKey="usecases.title">
                            Use-Cases
                        </Trans>
                    </Heading>
                    <Heading textAlign={"left"} fontSize={"40px"}>
                        <Trans i18nKey="usecases.description">
                            How description
                        </Trans>
                    </Heading>
                </VStack><Wrap spacing={"4em"} justify={"center"}>
                    <WrapItem as={Link} to={"/ooh"}>
                        <AudienceMeasurementUseCase />
                    </WrapItem>
                    <WrapItem>
                        <TrafficAnalysisUseCase />
                    </WrapItem>
                    <WrapItem>
                        <ParkingMonitoringUseCase />
                    </WrapItem>
                    <WrapItem>
                        <BehaviourClassificationUseCase />
                    </WrapItem>
                    <WrapItem>
                        <OccupancyMonitoringUseCase />
                    </WrapItem>
                    <WrapItem>
                        <AndMoreUseCases />
                    </WrapItem>
                </Wrap>
            </VStack>
        </>
    )
}

export default Usecases;