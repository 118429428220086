import * as React from "react"
import {
    Button,
    HStack,
    Heading,
    Image,
    Stack,
    StackDirection,
    VStack,
    useBreakpointValue,
    Show
} from "@chakra-ui/react";
import { Trans } from "react-i18next";
import bg from "../../../resources/images/cctv2.jpg"
import { IDS } from "../../../shared/Constants";

const Start = () => {
    const responsiveRowDirection = useBreakpointValue<StackDirection>({
        base: "column-reverse",
        lg: ["row"],
    });
    const responsiveWidth = useBreakpointValue({
        base: "20rem",
        sm: "25rem",
        md: "27rem",
        lg: "28rem",
        xl: "32rem",
    });
    return (
        <VStack justifyContent={"center"} alignItems={"left"} height={"36rem"} >
            <Stack direction={responsiveRowDirection} alignItems={"center"}>
                <VStack alignItems={"left"} >
                    <Heading textAlign={"left"} fontSize={"75px"}>
                        <Trans i18nKey="home.welcome.1">
                            No-code for analytics and surveillance computer vision applications
                        </Trans>
                    </Heading>
                    <Heading textAlign={"left"} fontSize={"40px"}>
                        <Trans i18nKey="home.welcome.2">
                            No-code for analytics and surveillance computer vision applications
                        </Trans>
                    </Heading>
                </VStack>
                <Show above={"lg"}>
                    <Image src={bg} alt="cctv" width={responsiveWidth} borderRadius={"15rem"} mt={"5rem"} mb={"1rem"} />
                </Show>
            </Stack>
            <HStack gap={"1rem"}>
                <Button bg={"primary.main"}
                    color={"primary.text"} as="a" href={`#${IDS.CONTACT}`}
                    colorScheme="primary"
                >
                    <Trans i18nKey="home.buttons.main">
                        Faire une essai
                    </Trans>
                </Button>
                <Button as="a" href={`#${IDS.PLATFORM}`}
                    colorScheme="gray" variant={"solid"} color={"gray.800"} bg={"gray.200"}
                >
                    <Trans i18nKey="home.buttons.secondary">
                        Learn more
                    </Trans>
                </Button>
            </HStack>
        </VStack>
    )
}

export default Start;
